<template>
    <div>
        <!-- <Header/> -->
        <Index/>
        <Footer/>
    </div>
</template>

<script>
import Header from '../../components/header' //头部
import Index from './children/index' //赞助列表
import Footer from '../../views/home/children/Footer' 
export default {
    components:{
        Header,
        Index,
        Footer
    }
}
</script>

<style lang="less" scoped>

</style>
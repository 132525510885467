<template>
    <div class="Transportations" :style="bgimgUrl">
        <div class="Transportations_contan">
            <div class="header">
                <h3>{{$t('SponsorShipText.title')}}</h3>
            </div>
            <div class="Transport">
                <ul class="Transport_Warp">
                    <li v-for="item in list" :key="item.id" class="list">
                        <div class="left">
                            <img v-lazy="item.imgUrl" alt="">
                        </div>
                        <div class="right">
                            <h3>{{item.titlea}}</h3>
                            <ul class="list_item">
                                <li class="item">
                                    <div class="box">{{item.Price}}</div>
                                    <div class="box_two">{{item.Company}}</div>
                                </li>
                                <li class="item">
                                    <div class="box">{{item.Matching}}</div>
                                    <div class="box_two">{{item.home}}</div>
                                </li>
                                <li class="item">
                                    <div class="box">{{item.Remarks}}</div>
                                    <div class="box_two">{{item.text}}</div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <div class="option">


                </div>
                <div class="warp_Contact ">
                    <p>{{$t('yourContactInfo')}}：</p>
                    <div class="listWarp">
                        <div class="label-root">
                            <label for="company">
                                <div class="label-container">
                                    <span>* {{$t('ApplicationExhibition.Company')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="company"
                                               v-model="application.company"
                                               class="input "
                                               :class="{acticeBorder:company == true}"
                                               @blur="changecompany">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="label-root">
                            <label for="Contacts">
                                <div class="label-container">
                                    <span>* {{$t('ApplicationExhibition.Contacts')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="Contacts"
                                               v-model="application.contacts"
                                               class="input"
                                               :class="{acticeBorder:contacts == true}"
                                               @blur="changecontacts">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="label-root">
                            <label for="Mobilephone">
                                <div class="label-container">
                                    <span>* {{$t('ApplicationExhibition.Mobilephone')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="Mobilephone"
                                               v-model="application.mobilephone"
                                               class="input"
                                               :class="{acticeBorder:mobilephone == true}"
                                               @blur="changemobilephone">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="label-root">
                            <label for="Mobilephone" class="VerificationCode">
                                <div class="label-container">
                                    <span>{{$t('ApplicationExhibition.VerificationCode')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="Mobilephone"
                                               v-model="application.verificationCode"
                                               class="input"
                                               :class="{acticeBorder:verificationCode == true}"
                                               @blur="changeverificationCode">
                                        <div class="after"></div>
                                    </div>
                                    <input type="button"
                                           class="btn"
                                           v-model="codeMsg"
                                           @click="Verification"
                                           :disabled="codeDisabled"/>
                                </div>
                            </label>
                        </div>
                        <div class="Submission" @click="Submission">
                            {{$t('SponsorShipText.submit')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {sponsorApply} from '../../../publicApi/sponsorApply'
    import {registersendCode} from '../../../publicApi/sponsorApply'
    import {Toast} from 'vant';
    import md5 from "md5";

    export default {
        data() {
            return {
                bgimgUrl: {
                    backgroundImage: "url(/images/img_bg_2@2x.png)",
                    backgroundRepeat: "no-repeat",
                },
                list: [
                    {
                        imgUrl: '/images/Sponsorship/img_shoutidai@2x.png',
                        titlea: this.$t('SponsorShipText.titleList')[0],
                        id: '1',
                        Price: this.$t('SponsorShipText.price'),
                        Matching: this.$t('SponsorShipText.number'),
                        Remarks: this.$t('SponsorShipText.note'),
                        Company: this.$t('SponsorShipText.wan', ['3']),
                        home: this.$t('SponsorShipText.only2'),
                        text: this.$t('SponsorShipText.remarks')[0]
                    },
                    {
                        imgUrl: '/images/Sponsorship/img_shoutidai@2x.png',
                        titlea: this.$t('SponsorShipText.titleList')[0],
                        id: '2',
                        Price: this.$t('SponsorShipText.price'),
                        Matching: this.$t('SponsorShipText.number'),
                        Remarks: this.$t('SponsorShipText.note'),
                        Company: this.$t('SponsorShipText.wan', ['3']),
                        home: this.$t('SponsorShipText.only2'),
                        text: this.$t('SponsorShipText.remarks')[0]
                    },
                    {
                        imgUrl: '/images/Sponsorship/img_yaoqinghan@2x.png',
                        titlea: this.$t('SponsorShipText.titleList')[1],
                        id: '3',
                        Price: this.$t('SponsorShipText.price'),
                        Matching: this.$t('SponsorShipText.number'),
                        Remarks: this.$t('SponsorShipText.note'),
                        Company: this.$t('SponsorShipText.wan', ['2']),
                        home: this.$t('SponsorShipText.only2'),
                        text: this.$t('SponsorShipText.remarks')[1]
                    },
                    {
                        imgUrl: '/images/Sponsorship/img_yaoqinghan@2x.png',
                        titlea: this.$t('SponsorShipText.titleList')[1],
                        id: '4',
                        Price: this.$t('SponsorShipText.price'),
                        Matching: this.$t('SponsorShipText.number'),
                        Remarks: this.$t('SponsorShipText.note'),
                        Company: this.$t('SponsorShipText.wan', ['2']),
                        home: this.$t('SponsorShipText.only2'),
                        text: this.$t('SponsorShipText.remarks')[1]
                    },
                    {
                        imgUrl: '/images/Sponsorship/img_jishiben@2x.png',
                        titlea: this.$t('SponsorShipText.titleList')[2],
                        id: '5',
                        Price: this.$t('SponsorShipText.price'),
                        Matching: this.$t('SponsorShipText.number'),
                        Remarks: this.$t('SponsorShipText.note'),
                        Company: this.$t('SponsorShipText.two'),
                        home: this.$t('SponsorShipText.exclusive'),
                        text: this.$t('SponsorShipText.remarks')[2]
                    },
                    {
                        imgUrl: '/images/Sponsorship/img_jishiben@2x.png',
                        titlea: this.$t('SponsorShipText.titleList')[2],
                        id: '6',
                        Price: this.$t('SponsorShipText.price'),
                        Matching: this.$t('SponsorShipText.number'),
                        Remarks: this.$t('SponsorShipText.note'),
                        Company: this.$t('SponsorShipText.two'),
                        home: this.$t('SponsorShipText.exclusive'),
                        text: this.$t('SponsorShipText.remarks')[2]
                    },
                ],
                application: {
                    company: '', //公司
                    contacts: '',//联系人
                    mobilephone: '',//手机
                    verificationCode: '',//验证码
                },
                codeDisabled: false,
                countdown: 60,
                codeMsg: this.$t('ApplicationExhibition.getValidateCode'),
                timer: null,
                valscode: false,
                company: false, //公司
                contacts: false,//联系人
                mobilephone: false,//手机
                verificationCode: false //验证码
            }
        },
        methods: {
            getSubmission() {
                let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                if (this.application.company === '') {
                    this.company = true
                    Toast('公司不能为空');
                } else if (this.application.contacts === '') {
                    this.contacts = true
                    Toast('联系人不能为空')
                } else if (this.application.mobilephone === '') {
                    this.mobilephone = true
                    Toast('手机号码不能为空')
                } else if (!reg.test(this.application.mobilephone)) {
                    this.mobilephone = true
                    Toast('你的手机号码有误请重新输入')
                } else if (this.valscode == false) {
                    Toast('请先获取验证码')
                }
                {
                    return true
                }
            },
            //提交赞助
            Submission() {
                if (this.getSubmission()) {
                    this.getCurrentApicationsponsorApply()
                }
            },
            async getCurrentApicationsponsorApply() {
                const channel = localStorage.getItem('conmart.channel') || '';
                let res = await sponsorApply({
                    code: this.application.verificationCode,
                    channel: channel,
                    company: this.application.company || '',
                    email: '',
                    lang: this.$transformAPILang(),
                    mobile: this.application.mobilephone,
                    name: this.application.contacts,
                    expo: 'km2020',
                    purpose: 'abc'
                })
                if (res.status == 2 && res.data == null) {
                    Toast('你好！ 你的手机号 已经申请了 请勿重复提交')
                    this.Clearinputbox()
                }
                if (res.status == -1 && res.data == null) {
                    console.log('验证码格式有误')
                }
                if (res.status == 2 && res.data == false) {
                    Toast('你输入的验证码有误 请重新获取')
                }
                if (res.status == 0 && res.data == true) {
                    Toast('恭喜你 提交成功')
                    this.Clearinputbox()
                }
            },
            async getCurrentApplicationesendCode() {
                const isZH = this.$route.params.lang === 'zh';
                let target = isZH ? this.application.mobilephone : this.application.mailbox;
                let n = ~~(Math.random() * 10);
                let time = Date.now();
                let code = `${n}${md5(`${n}${time}${target}`)}`;
                let res = await registersendCode({
                    code: code,
                    target: target,
                    time: time,
                    type: isZH ? 'Sms' : 'Email',
                    key: 'SponsorApply'
                });
                if (res.status == 0 && res.data == true) {
                    Toast(isZH ? '验证码发送成功，请注意查看你手机' : this.$t('邮件发送成功，请注意查看你的邮件'));
                }
            },
            validateBtn() {
                if (!this.timer) {
                    this.timer = setInterval(() => {
                        if (this.countdown > 0 && this.countdown <= 60) {
                            this.countdown--;
                            if (this.countdown !== 0) {
                                this.codeMsg = "重新发送(" + this.countdown + ")";
                            } else {
                                clearInterval(this.timer);
                                this.codeMsg = "获取验证码";
                                this.countdown = 60;
                                this.timer = null;
                                this.codeDisabled = false;
                            }
                        }
                    }, 1000)
                }
            },//倒计时
            validataaction() {
                let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                if (this.application.mobilephone === '') {
                    Toast('手机号码不能为空')
                } else if (!reg.test(this.application.mobilephone)) {
                    Toast('你的手机号码有误请重新输入')
                } else {
                    return true
                }
            },//手机号验证规则
            //获取验证码
            Verification() {
                if (this.validataaction()) {
                    this.validateBtn();
                    this.getCurrentApplicationesendCode()
                }
                this.valscode = true
            },
            Clearinputbox() {
                this.application.company = '',
                    this.application.contacts = '',
                    this.application.mobilephone = '',
                    this.application.verificationCode = '',
                    this.application.mailbox = ''
            },
            changecompany() {
                this.company = false
            },
            changecontacts() {
                this.contacts = false
            },
            changemobilephone() {
                this.mobilephone = false
            },
            changeverificationCode() {
                this.verificationCode = false
            }

        }
    }
</script>

<style lang="scss" scoped>
    .Transportations {
        width: 100%;
        background: rgba(246, 246, 246, 1);
        background-position-y: 503px;
    }

    .Transportations_contan {
        margin: auto;
        /*height: 917px;*/
        background: rgba(255, 255, 255, 1);
        padding-top: 80px;
        @include design-width-setting;
    }

    .header {
        width: 100%;
        height: 126px;
        line-height: 126px;
        text-align: center;

        h3 {
            font-weight: 600;
            color: rgba(34, 24, 21, 1);
            font-size: 32px;
        }
    }

    .Transport {
        width: 88%;
        margin: auto;
    }

    .Transport_Warp {
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .list {
            width: 568px;
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            margin: 0 auto 17px;

            .left {
                width: 195px;
                height: 134px;
                background: #D1D1D1;

                img {
                    // width: 195px;
                    // height: 134px;
                    width: 100%;
                    height: 100%;
                }
            }

            .right {
                width: 100%;
                border-bottom: 1px solid #FAD214;

                h3 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background: #FAD214;
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 16px;
                    text-align: center;
                }

                .list_item {

                    .item {
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;

                        .box {
                            width: 80px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            font-weight: 400;
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            border: 1px solid #FAD214;
                            border-top: none;
                        }

                        .box_two {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            overflow: hidden;
                            text-align: center;
                            font-weight: 400;
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            border: 1px solid #FAD214;
                            border-top: none;
                        }
                    }
                }
            }
        }

    }

    .option {
        height: 30px;
        /*width: 400px;*/
        margin: auto;

        ul {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;

            li {
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border: 1px solid #cccccc;
                margin: 0 10px;
            }
        }
    }

    .warp_Contact {
        margin: auto;
        padding: 30px 0;
        text-align: center;

        p {
            font-weight: 400;
            color: rgba(34, 24, 21, 1);
        }
    }

    .listWarp {
        margin: auto;
        box-sizing: border-box;
        position: relative;
        text-align: center;

        .label-root {
            margin: 0 auto;
        }

        label {
            margin-top: 10px;
            display: inline-block;

            @include sm {
                width: 100%;
            }
        }

        .label-container {
            display: flex;
            align-items: center;

            > span {
                width: 80px;
                text-align: left;
            }

            .input-container {
                position: relative;

                @include sm {
                    width: 100%;
                }
            }

            .input {
                border-bottom: 1px solid #D8D8D8;
                width: 320px;
                font-size: 14px;
                padding: 2px;
                height: 44px;
                position: relative;

                & + .after {
                    content: '';
                    position: absolute;
                    display: block;
                    z-index: 1;
                    width: 100%;
                    bottom: 0;
                    height: 1px;
                    background: darken(#fed900, 5%);
                    transform: scale(0, 1);
                    transform-origin: left center;
                    transition: .3s linear;
                    opacity: 0;
                }

                &:focus + .after {
                    opacity: 1;
                    transform: scale(1, 1);
                }

                @include md {
                    width: 320px;
                }
                @include sm {
                    width: 100%;
                }
            }
        }
    }

    .Submission {
        width: 128px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #FAD214;
        color: #ffffff;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        margin: auto;
        margin-top: 40px;
        cursor: pointer;
    }


    .VerificationCode {
        position: relative;

        .btn {
            @include validateBtn();
        }
    }


</style>
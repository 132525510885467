var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Transportations", style: _vm.bgimgUrl }, [
    _c("div", { staticClass: "Transportations_contan" }, [
      _c("div", { staticClass: "header" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("SponsorShipText.title")))])
      ]),
      _c("div", { staticClass: "Transport" }, [
        _c(
          "ul",
          { staticClass: "Transport_Warp" },
          _vm._l(_vm.list, function(item) {
            return _c("li", { key: item.id, staticClass: "list" }, [
              _c("div", { staticClass: "left" }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: item.imgUrl,
                      expression: "item.imgUrl"
                    }
                  ],
                  attrs: { alt: "" }
                })
              ]),
              _c("div", { staticClass: "right" }, [
                _c("h3", [_vm._v(_vm._s(item.titlea))]),
                _c("ul", { staticClass: "list_item" }, [
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "box" }, [
                      _vm._v(_vm._s(item.Price))
                    ]),
                    _c("div", { staticClass: "box_two" }, [
                      _vm._v(_vm._s(item.Company))
                    ])
                  ]),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "box" }, [
                      _vm._v(_vm._s(item.Matching))
                    ]),
                    _c("div", { staticClass: "box_two" }, [
                      _vm._v(_vm._s(item.home))
                    ])
                  ]),
                  _c("li", { staticClass: "item" }, [
                    _c("div", { staticClass: "box" }, [
                      _vm._v(_vm._s(item.Remarks))
                    ]),
                    _c("div", { staticClass: "box_two" }, [
                      _vm._v(_vm._s(item.text))
                    ])
                  ])
                ])
              ])
            ])
          }),
          0
        ),
        _c("div", { staticClass: "option" }),
        _c("div", { staticClass: "warp_Contact " }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("yourContactInfo")) + "：")]),
          _c("div", { staticClass: "listWarp" }, [
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "company" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      "* " + _vm._s(_vm.$t("ApplicationExhibition.Company"))
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.application.company,
                          expression: "application.company"
                        }
                      ],
                      staticClass: "input ",
                      class: { acticeBorder: _vm.company == true },
                      attrs: { type: "text", name: "", id: "company" },
                      domProps: { value: _vm.application.company },
                      on: {
                        blur: _vm.changecompany,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.application,
                            "company",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "after" })
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "Contacts" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      "* " + _vm._s(_vm.$t("ApplicationExhibition.Contacts"))
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.application.contacts,
                          expression: "application.contacts"
                        }
                      ],
                      staticClass: "input",
                      class: { acticeBorder: _vm.contacts == true },
                      attrs: { type: "text", name: "", id: "Contacts" },
                      domProps: { value: _vm.application.contacts },
                      on: {
                        blur: _vm.changecontacts,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.application,
                            "contacts",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "after" })
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "label-root" }, [
              _c("label", { attrs: { for: "Mobilephone" } }, [
                _c("div", { staticClass: "label-container" }, [
                  _c("span", [
                    _vm._v(
                      "* " + _vm._s(_vm.$t("ApplicationExhibition.Mobilephone"))
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.application.mobilephone,
                          expression: "application.mobilephone"
                        }
                      ],
                      staticClass: "input",
                      class: { acticeBorder: _vm.mobilephone == true },
                      attrs: { type: "text", name: "", id: "Mobilephone" },
                      domProps: { value: _vm.application.mobilephone },
                      on: {
                        blur: _vm.changemobilephone,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.application,
                            "mobilephone",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("div", { staticClass: "after" })
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "label-root" }, [
              _c(
                "label",
                {
                  staticClass: "VerificationCode",
                  attrs: { for: "Mobilephone" }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("ApplicationExhibition.VerificationCode"))
                      )
                    ]),
                    _c("div", { staticClass: "input-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.application.verificationCode,
                            expression: "application.verificationCode"
                          }
                        ],
                        staticClass: "input",
                        class: { acticeBorder: _vm.verificationCode == true },
                        attrs: { type: "text", name: "", id: "Mobilephone" },
                        domProps: { value: _vm.application.verificationCode },
                        on: {
                          blur: _vm.changeverificationCode,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.application,
                              "verificationCode",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("div", { staticClass: "after" })
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.codeMsg,
                          expression: "codeMsg"
                        }
                      ],
                      staticClass: "btn",
                      attrs: { type: "button", disabled: _vm.codeDisabled },
                      domProps: { value: _vm.codeMsg },
                      on: {
                        click: _vm.Verification,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.codeMsg = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "Submission", on: { click: _vm.Submission } },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("SponsorShipText.submit")) +
                    "\n                    "
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }